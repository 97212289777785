import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { activeRoute } from "functions/utils";
import { Route } from "types/routes";

import {
  backgroundSx,
  brandSx,
  drawerPaperSx,
  listItemActiveSx,
  listItemSx,
  listItemIconSx,
  listItemTextSx,
  sidebarWrapperSx,
} from "assets/jss/sidebar";

export interface SidebarProps {
  open: boolean;
  handleDrawerToggle: () => void;
  image: string;
  logoText: string;
  routes: Route[];
}

export default function Sidebar(props: SidebarProps) {
  const location = useLocation();
  const { image, logoText, routes } = props;

  function handleNavlinkClick() {
    if (props.open) {
      props.handleDrawerToggle();
    }
  }

  const links = React.useMemo(
    () => (
      <List
        sx={{
          marginTop: "20px",
          paddingLeft: "0",
          paddingTop: "0",
          paddingBottom: "0",
          marginBottom: "0",
          listStyle: "none",
          position: "unset",
        }}
      >
        {routes.map((prop, key) => {
          if (!prop.icon) {
            return null;
          }
          const isActive = activeRoute(prop.path, location);
          return (
            <NavLink
              to={prop.path.replace("/*", "")}
              className="nav-link"
              onClick={handleNavlinkClick}
              key={key}
            >
              <ListItemButton sx={[listItemSx, isActive && listItemActiveSx]}>
                <prop.icon sx={listItemIconSx} />
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  sx={listItemTextSx}
                />
              </ListItemButton>
            </NavLink>
          );
        })}
      </List>
    ),
    [location.pathname]
  );
  const brand = (
    <Box sx={brandSx}>
      <a href="#">{logoText}</a>
    </Box>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          PaperProps={{
            sx: drawerPaperSx,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <Box sx={sidebarWrapperSx}>{links}</Box>
          {image !== undefined ? (
            <Box
              sx={backgroundSx}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          PaperProps={{
            sx: drawerPaperSx,
          }}
        >
          {brand}
          <Box sx={sidebarWrapperSx}>{links}</Box>
          {image !== undefined ? (
            <Box
              sx={backgroundSx}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}
