import React from "react";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Dialog from "components/Dialog";

import { apiCall } from "functions/api";
import { Playlist, UpdateRequest } from "types/playlists";
import { Media, MediaType } from "types/media";

interface Props {
  open: boolean;
  playlist: Playlist;
  type: MediaType;
  onClose: (reload: boolean) => void;
}

interface SortableItemProps {
  media: Media;
}

function SortableItem(props: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.media.id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: "10px",
    backgroundColor: "#e5e5e5",
    borderRadius: "3px",
    boxSizing: "border-box",
    marginBottom: "5px",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.media.metadata.name}
    </div>
  );
}

const ReorderDialog = (props: Props) => {
  const [items, setItems] = React.useState<Media[]>();
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = () => {
    setSubmitting(true);
    setServerError("");
    const ids = items!.map((item) => item.id);
    const newSequence = props.playlist.sequence.filter(
      (id) => !ids.includes(id)
    ) as number[];
    newSequence.push(...items!.map((item) => item.id));
    const request: UpdateRequest = {
      sequence: newSequence,
    };
    apiCall(`/playlists/${props.playlist.id}`, "POST", request)
      .then(() => {
        setSubmitting(false);
        props.onClose(true);
      })
      .catch((e) => {
        console.error(e);
        setSubmitting(false);
        setServerError("An error occurred");
      });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items!.findIndex((item) => item.id === active.id);
        const newIndex = items!.findIndex((item) => item.id === over.id);

        return arrayMove(items!, oldIndex, newIndex);
      });
    }
  };

  React.useEffect(() => {
    if (props.open && props.playlist.sequence_expanded) {
      setItems(
        props.playlist.sequence_expanded.filter(
          (item) => item && item.type === props.type
        ) as Media[]
      );
    }
  }, [props.open, props.playlist, props.type]);

  if (!items) {
    return null;
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Reorder Media"
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: submitting ? "Submitting" : "Submit",
          color: "primary",
          submit: true,
          disabled: submitting,
        },
      ]}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((media) => (
            <SortableItem key={media.id} media={media} />
          ))}
        </SortableContext>
      </DndContext>
      {serverError && (
        <span style={{ color: "red", fontSize: "0.75em" }}>{serverError}</span>
      )}
    </Dialog>
  );
};

export default ReorderDialog;
