import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { apiCall } from "functions/api";
import { Playlist } from "types/playlists";
import { ListResponse } from "types/utils";

interface Props {
  label?: string;
  value?: Playlist | null;
  onChange: (value: Playlist | null) => void;
  disabledIds?: number[];
  disabled?: boolean;
}

function PlaylistSearch(props: Props) {
  const [inputValue, setInputValue] = React.useState("");
  const [data, setData] = React.useState<Playlist[]>();
  const [isLoading, setIsLoading] = React.useState(true);

  const getOptionLabel = (option: Playlist) => {
    return option.metadata.name;
  };

  const isOptionEqualToValue = (option: Playlist, value: Playlist) =>
    option.id === value.id;

  React.useEffect(() => {
    const id = setTimeout(() => {
      const request = {
        search: inputValue || undefined,
        limit: 20,
      };
      setIsLoading(true);
      apiCall("/playlists", "GET", request).then(
        (response: ListResponse<Playlist>) => {
          setData(response.data);
          setIsLoading(false);
        },
        (e: any) => {
          setIsLoading(false);
          console.error(e);
        }
      );
    }, 300);

    return () => clearTimeout(id);
  }, [inputValue]);

  return (
    <Autocomplete<Playlist>
      options={data?.filter((d) => !props.disabledIds?.includes(d.id)) || []}
      disabled={props.disabled}
      value={props.value}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      loading={isLoading}
      ListboxProps={{ sx: { maxHeight: 200 } }}
      onChange={(_, value) => props.onChange(value)}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField {...params} fullWidth label={props.label} />
      )}
    />
  );
}

export default PlaylistSearch;
