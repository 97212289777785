import React from "react";

import MUICard from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface MenuItemProps {
  label: string;
  onClick: () => void;
}

interface Props {
  title: string;
  button?: string;
  buttonDisabled?: boolean;
  buttonOnClick?: () => void;
  padding?: boolean;
  loading?: boolean;
  empty?: boolean;
  emptyText?: string;
  children?: React.ReactNode;
  menu?: MenuItemProps[];
}

const Card = (props: Props) => {
  const contentPadding =
    props.padding === false && !props.loading && !props.empty;
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLButtonElement>(null);

  const handleMenuItemClick = (item: MenuItemProps) => {
    setMenuOpen(false);
    item.onClick();
  };

  return (
    <MUICard>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            padding: "15px",
            fontFamily: '"Quicksand", sans-serif',
          }}
        >
          {props.title}
        </Box>
        <Box
          sx={{ paddingRight: "15px", display: "flex", alignItems: "center" }}
        >
          {props.button && (
            <Button
              variant="contained"
              size="small"
              disabled={props.buttonDisabled}
              onClick={props.buttonOnClick}
            >
              {props.button}
            </Button>
          )}
          {props.menu && (
            <IconButton ref={anchorEl} onClick={() => setMenuOpen(true)}>
              <MoreVertIcon />
            </IconButton>
          )}
          {props.menu && (
            <Menu
              anchorEl={anchorEl.current}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              {props.menu.map((item) => (
                <MenuItem
                  key={item.label}
                  onClick={() => handleMenuItemClick(item)}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          )}
        </Box>
      </Box>
      <Divider />
      <div
        style={{
          padding: contentPadding ? "0" : "15px",
        }}
      >
        {props.loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        {props.empty &&
          !props.loading &&
          (props.emptyText || "No data to display.")}
        {!props.loading && !props.empty && props.children}
      </div>
    </MUICard>
  );
};

export default Card;
