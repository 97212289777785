import React from "react";

import Dialog from "components/Dialog";
import Select from "components/Select";
import ValidatedInput, { initialValue } from "components/ValidatedInput";
import Combo from "components/Combo";
import Dropzone from "components/Dropzone";

import { apiCall, createAndUploadFile } from "functions/api";
import { useAppContext } from "functions/context";
import { mediaKeyNames } from "functions/media";
import {
  getDurationInSeconds,
  getDurationString,
  youtubeIdOptionalRegex,
} from "functions/utils";

import type {
  InsertRequest,
  Media,
  MediaType,
  UpdateRequest,
} from "types/media";
import type { Playlist } from "types/playlists";
import type { Speaker } from "types/speakers";
import { InsertResponse } from "types/utils";

interface Props {
  open: boolean;
  media?: Media;
  all: Media[];
  playlist: Playlist;
  onClose: (reload: boolean) => void;
}

const MediaDialog = (props: Props) => {
  const [name, setName] = React.useState(initialValue("text"));
  const [description, setDescription] = React.useState(initialValue("any"));
  const [location, setLocation] = React.useState(initialValue("text"));
  const [date, setDate] = React.useState(initialValue("date"));
  const [lectureNumber, setLectureNumber] = React.useState(
    initialValue("number")
  );
  const [duration, setDuration] = React.useState(initialValue("duration"));
  const [type, setType] = React.useState<MediaType | "">("");
  const [speakers, setSpeakers] = React.useState<Speaker[]>();
  const [speakerId, setSpeakerId] = React.useState<number>(0);
  const [clipMediaId, setClipMediaId] = React.useState<number>();
  const [youtubeLink, setYoutubeLink] = React.useState(
    initialValue(youtubeIdOptionalRegex)
  );
  const [audioFile, setAudioFile] = React.useState<File>();
  const [audioFileProgress, setAudioFileProgress] = React.useState<number>(0);
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");
  const context = useAppContext();

  const error = React.useMemo(() => {
    return (
      !name?.success ||
      !description?.success ||
      !location?.success ||
      !date?.success ||
      (type === "speech" && !lectureNumber?.success) ||
      !duration?.success ||
      !type ||
      !youtubeLink?.success
    );
  }, [
    name,
    description,
    location,
    date,
    lectureNumber,
    duration,
    type,
    youtubeLink,
  ]);

  const loading = !speakers;

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleDrop = (files: File[]) => {
    if (files.length) {
      setAudioFile(files[0]);
    }
  };

  const handleClipMediaChange = (value: string | string[]) => {
    if (value) {
      setClipMediaId(parseInt(value as string, 10));
    } else {
      setClipMediaId(undefined);
    }
  };

  const handleSubmit = async () => {
    if (error) {
      return;
    }
    setSubmitting(true);
    setServerError("");
    try {
      const metadata: Media["metadata"] = {
        name: name.value,
        description: description.value,
        location: location.value,
        date: date.value,
        year: parseInt(date.value.split("-")[0]),
        lecture_number:
          type === "speech" ? parseInt(lectureNumber.value) : undefined,
        duration: getDurationInSeconds(duration.value),
      };
      if (!props.media) {
        const youtubeId = youtubeLink.value.match(youtubeIdOptionalRegex)?.[2];
        let request: InsertRequest = {
          metadata,
          type: type as MediaType,
          playlist_id: props.playlist.id,
          parent_media_id: clipMediaId,
          speaker_id: speakerId,
        };
        if (youtubeId) {
          request.video = {
            main: {
              id: youtubeId,
              source: "youtube",
            },
          };
        }
        if (audioFile) {
          const cloudFile = await createAndUploadFile(
            context.defaultStorage,
            audioFile,
            "audio",
            setAudioFileProgress
          );
          request.audio = {
            main: {
              id: cloudFile,
              source: context.defaultStorage,
            },
          };
        }
        const response: InsertResponse = await apiCall(
          "/media",
          "POST",
          request
        );
        console.log(response);
        if (youtubeId) {
          try {
            await apiCall(`/media/${response.id}/generate_image`, "POST");
            await apiCall(`/media/${response.id}/generate_small_images`, "POST");
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        const request: UpdateRequest = {
          metadata,
        };
        const youtubeId = youtubeLink.value.match(youtubeIdOptionalRegex)?.[2];
        if (youtubeId) {
          request.video = {
            main: {
              id: youtubeId,
              source: "youtube",
            },
          };
        } else {
          request.video = { main: null };
        }
        await apiCall(`/media/${props.media.id}`, "POST", request);
        if (youtubeId) {
          try {
            await apiCall(`/media/${props.media.id}/generate_image`, "POST");
            await apiCall(`/media/${props.media.id}/generate_small_images`, "POST");
          } catch (e) {
            console.error(e);
          }
        }
      }
      setSubmitting(false);
      props.onClose(true);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      setServerError("An error occurred");
    }
  };

  const loadSpeakers = React.useCallback(async () => {
    setSpeakers(undefined);
    const media = props.media;
    try {
      const response = await apiCall("/speakers");
      const data: Speaker[] = response.data;
      setSpeakers(data);
      if (
        media?.speaker_id &&
        data.find((speaker) => speaker.id === media.speaker_id)
      ) {
        setSpeakerId(media.speaker_id);
      }
    } catch (e) {
      console.error(e);
    }
  }, [props.media]);

  React.useEffect(() => {
    if (props.open) {
      const media = props.media;
      const playlist = props.playlist;
      setType("");
      setAudioFile(undefined);
      setAudioFileProgress(0);
      setSpeakerId(0);
      setServerError("");
      if (media) {
        const duration = getDurationString(media.metadata.duration);
        setName(initialValue("text", media.metadata.name));
        setType(media.type);
        setDescription(initialValue("any", media.metadata.description));
        setLocation(initialValue("text", media.metadata.location));
        setDate(initialValue("date", media.metadata.date));
        setDuration(initialValue("duration", duration));
        if (media.type === "speech") {
          setLectureNumber(
            initialValue("number", media.metadata.lecture_number!.toString())
          );
        }
        setClipMediaId(
          props.all.find((m) => m.id === media.parent_media_id)
            ? media.parent_media_id!
            : undefined
        );
        if (media.video?.main?.source === "youtube") {
          setYoutubeLink(
            initialValue(
              youtubeIdOptionalRegex,
              `https://www.youtube.com/watch?v=${media.video.main.id}`
            )
          );
        } else {
          setYoutubeLink(initialValue(youtubeIdOptionalRegex));
        }
      } else {
        setName(initialValue("text"));
        setType("");
        setDescription(initialValue("any"));
        setLocation(initialValue("text", playlist.metadata.location));
        setDate(initialValue("date", playlist.metadata.date));
        setLectureNumber(initialValue("number", "1"));
        setDuration(initialValue("duration"));
        setClipMediaId(undefined);
        setYoutubeLink(initialValue(youtubeIdOptionalRegex));
      }
      loadSpeakers();
    }
  }, [props.open, props.media, props.playlist, props.all, loadSpeakers]);

  return (
    <Dialog
      open={props.open}
      loading={loading}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.media ? "Edit Media" : "Create Media"}
      errorMessage={serverError}
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: submitting ? "Submitting" : "Submit",
          color: "primary",
          submit: true,
          disabled: error || submitting,
        },
      ]}
      progress={
        audioFileProgress
          ? {
              value: audioFileProgress,
            }
          : undefined
      }
    >
      {clipMediaId && (
        <Select
          label="Complete File"
          value={clipMediaId?.toString() || ""}
          onChange={handleClipMediaChange}
          items={props.all.map((item) => ({
            label: item.metadata.name,
            value: item.id.toString(),
          }))}
        />
      )}
      <ValidatedInput
        label="Name"
        value={name}
        onChange={setName}
        type="text"
      />
      {!props.media && (
        <Select
          label="Category"
          value={type}
          onChange={setType as any}
          items={mediaKeyNames.map((item) => ({
            label: item.name,
            value: item.key,
          }))}
        />
      )}
      {type === "speech" && (
        <ValidatedInput
          label="Lecture Number"
          value={lectureNumber}
          onChange={setLectureNumber}
          type="number"
        />
      )}
      <ValidatedInput
        label="Description"
        value={description}
        onChange={setDescription}
        type="any"
      />
      <ValidatedInput
        label="Location"
        value={location}
        onChange={setLocation}
        type="text"
      />
      <ValidatedInput
        label="Date (YYYY-MM-DD)"
        value={date}
        onChange={setDate}
        type="date"
      />
      <ValidatedInput
        label="Duration (HH:MM:SS)"
        value={duration}
        onChange={setDuration}
        type="duration"
      />
      {speakers && (
        <Combo
          items={[
            {
              label: "None",
              value: "0",
            },
            ...speakers.map((speaker) => ({
              label: speaker.metadata.name,
              value: speaker.id.toString(),
            })),
          ]}
          label="Speaker"
          value={speakerId.toString()}
          onChange={(value) => setSpeakerId(value ? parseInt(value) : 0)}
        />
      )}
      <ValidatedInput
        label="Youtube Link"
        value={youtubeLink}
        onChange={setYoutubeLink}
        type={youtubeIdOptionalRegex}
      />
      {!props.media && (
        <>
          <label>Audio File</label>
          <Dropzone type="audio" onDrop={handleDrop} file={audioFile} />
        </>
      )}
    </Dialog>
  );
};

export default MediaDialog;
