import Home from "@mui/icons-material/Home";
import List from "@mui/icons-material/List";
import Tag from "@mui/icons-material/Tag";
import Speaker from "@mui/icons-material/Person";
import Notifications from "@mui/icons-material/Notifications";
import Logout from "@mui/icons-material/Logout";

import AnnouncementsPage from "pages/Announcements";
import PlaylistsPage from "pages/Playlists";
import LogoutPage from "pages/Logout";
import TagsPage from "pages/Tags";
import SpeakersPage from "pages/Speakers";
import HomePage from "pages/Home";

import type { Route } from "types/routes";

const routes: Route[] = [
  {
    path: "/",
    icon: Home,
    name: "Home",
    element: HomePage,
  },
  {
    path: "/playlists/*",
    icon: List,
    name: "Playlists",
    element: PlaylistsPage,
  },
  {
    path: "/tags",
    icon: Tag,
    name: "Tags",
    element: TagsPage,
  },
  {
    path: "/speakers",
    icon: Speaker,
    name: "Speakers",
    element: SpeakersPage,
  },
  {
    path: "/announcements",
    icon: Notifications,
    name: "Announcements",
    element: AnnouncementsPage,
  },
  {
    path: "/logout",
    icon: Logout,
    name: "Logout",
    element: LogoutPage,
  },
];

export default routes;
