import React from "react";
import { useLocation } from "react-router-dom";

import { AppBar, Box, Hidden, IconButton, Toolbar } from "@mui/material";
import Menu from "@mui/icons-material/Menu";

import { Route } from "types/routes";
import { activeRoute } from "functions/utils";

import { grayColor, containerSx } from "assets/jss/main";

export interface NavbarProps {
  handleDrawerToggle: () => void;
  routes: Route[];
}

const Navbar = (props: NavbarProps) => {
  const location = useLocation();

  const page = React.useMemo(() => {
    return props.routes.find((route) => activeRoute(route.path, location));
  }, [location]);

  return (
    <AppBar
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100%",
        paddingTop: "10px",
        zIndex: "1029",
        color: grayColor[7],
        border: "0",
        borderRadius: "3px",
        padding: "10px 0",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block",
      }}
    >
      <Toolbar
        sx={{
          ...containerSx,
          minHeight: "50px",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              letterSpacing: "unset",
              lineHeight: "30px",
              fontSize: "20px",
              textTransform: "none",
              color: "inherit",
              fontWeight: "200",
              margin: "0",
              "&:hover,&:focus": {
                background: "transparent",
              },
            }}
          >
            {page?.name}
          </Box>
        </Box>
        <Hidden smDown implementation="css">
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
