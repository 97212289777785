import React from "react";

import Dialog from "components/Dialog";
import ValidatedInput, { initialValue } from "components/ValidatedInput";

import { apiCall } from "functions/api";

import type { Tag } from "types/tags";

interface Props {
  open: boolean;
  tag?: Tag;
  onClose: (reload: boolean) => void;
}

const TagDialog = (props: Props) => {
  const [name, setName] = React.useState(initialValue("text"));
  const [description, setDescription] = React.useState(initialValue("any"));
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");

  const error = React.useMemo(() => {
    return !name?.success || !description?.success;
  }, [name, description]);

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = () => {
    if (error) {
      return;
    }
    setSubmitting(true);
    setServerError("");
    const url = props.tag ? `/tags/${props.tag.id}` : "/tags";
    apiCall(url, "POST", {
      metadata: {
        name: name?.value,
        description: description?.value,
      },
    })
      .then((response) => {
        setSubmitting(false);
        props.onClose(true);
      })
      .catch((e) => {
        console.error(e);
        setSubmitting(false);
        setServerError("An error occurred");
      });
  };

  React.useEffect(() => {
    if (props.open) {
      if (props.tag) {
        setName(initialValue("text", props.tag.metadata.name));
        setDescription(initialValue("any", props.tag.metadata.description));
      } else {
        setName(initialValue("text"));
        setDescription(initialValue("any"));
      }
    }
  }, [props.open, props.tag]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.tag ? "Edit Tag" : "Create Tag"}
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: submitting ? "Submitting" : "Submit",
          color: "primary",
          submit: true,
          disabled: error || submitting,
        },
      ]}
    >
      <ValidatedInput
        label="Name"
        value={name}
        onChange={setName}
        type="text"
      />
      <ValidatedInput
        label="Description"
        value={description}
        onChange={setDescription}
        type="any"
      />
      {serverError && (
        <span style={{ color: "red", fontSize: "0.75em" }}>{serverError}</span>
      )}
    </Dialog>
  );
};

export default TagDialog;
