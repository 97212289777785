import React from "react";

import Card from "components/Card";
import SpeakerDialog from "./Dialog";
import ConfirmDialog from "components/ConfirmDialog";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import { Speaker } from "types/speakers";
import { apiCall } from "functions/api";

const SpeakersPage = () => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<Speaker[]>();
  const [selectedSpeaker, setSelectedSpeaker] = React.useState<Speaker>();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      { field: "id", headerName: "ID", width: 90 },
      {
        field: "name",
        headerName: "Name",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.metadata?.name,
        width: 150,
      },
      {
        field: "actions",
        type: "actions",
        align: "right",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            label="Edit"
            showInMenu
            onClick={() => handleEdit(params.row)}
          />,
          <GridActionsCellItem
            label="Delete"
            showInMenu
            onClick={() => handleDelete(params.row)}
          />,
        ],
      },
    ],
    []
  );

  const loadData = () => {
    setLoading(true);
    apiCall("/speakers").then(
      (response: { data: Speaker[] }) => {
        setData(response.data);
        setLoading(false);
      },
      (e) => {
        console.error(e);
      }
    );
  };

  const handleEdit = (row: Speaker) => {
    setSelectedSpeaker(row);
    setEditDialogOpen(true);
  };

  const handleDelete = (row: Speaker) => {
    setSelectedSpeaker(row);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = (reload: boolean) => {
    setEditDialogOpen(false);
    setDeleteDialogOpen(false);
    setSelectedSpeaker(undefined);
    if (reload) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card
        title="Speakers"
        button="Add"
        buttonOnClick={() => setEditDialogOpen(true)}
        padding={false}
        loading={loading}
        empty={data?.length === 0}
      >
        <DataGrid
          rows={data || []}
          columns={columns}
          pageSizeOptions={[50]}
          rowSelection={false}
          disableRowSelectionOnClick
          disableColumnMenu
        />
      </Card>
      <SpeakerDialog
        open={editDialogOpen}
        onClose={handleDialogClose}
        speaker={selectedSpeaker}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        title="Delete Speaker"
        text="Are you sure you want to delete this speaker?"
        apiLink={`/speakers/${selectedSpeaker?.id}`}
      />
    </div>
  );
};

export default SpeakersPage;
