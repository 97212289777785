import { Typography } from "@mui/material";
import Dialog from "components/Dialog";
import TagSearch from "components/TagSearch";
import React from "react";
import { Tag } from "types/tags";

type Props = {
  open: boolean;
  tag?: Tag | null;
  onSubmit: (p: Tag) => Promise<void>;
  disabledIds?: number[];
  onClose: () => void;
};
export default function SelectTagDialog(props: Props) {
  const [tag, setTag] = React.useState<Tag | null>(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");

  React.useEffect(() => {
    setTag(props.tag || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const handleClose = () => {
    if (!submitting) {
      props.onClose();
    }
  };

  const handleSubmit = () => {
    if (!tag) return;
    setSubmitting(true);
    setServerError("");
    props
      .onSubmit(tag)
      .then(() => {
        setSubmitting(false);
        props.onClose();
      })
      .catch((e) => {
        console.error(e);
        setServerError("An error occurred");
        setSubmitting(false);
      });
  };

  return (
    <Dialog
      title="Select Tag"
      open={props.open}
      onClose={() => handleClose()}
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: submitting ? "Submitting" : "Submit",
          color: "primary",
          onClick: handleSubmit,
          disabled: !tag || submitting,
        },
      ]}
    >
      <TagSearch
        label="Select a tag"
        disabled={submitting}
        disabledIds={props.disabledIds}
        value={tag}
        onChange={setTag}
      />
      {serverError && <Typography color="error">{serverError}</Typography>}
    </Dialog>
  );
}
