import React, { StyleHTMLAttributes } from "react";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";

interface Props {
  label: React.ReactNode;
  children: React.ReactNode;
}

const Details = (props: Props) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        fontWeight: 400,
        [theme.breakpoints.down("md")]: {
          display: "block",
        },
      })}
    >
      <Box
        sx={{
          color: "#888888",
          flex: "0 0 250px",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        {props.label}
      </Box>
      <Box
        sx={{
          flex: "1 1 0px",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default Details;
