import { MediaType } from "types/media";

export const mediaKeyNames: { key: MediaType, name: string }[] = [
  { key: "clip", name: "Clips" },
  { key: "speech", name: "Speeches" },
  { key: "song", name: "Songs" },
  { key: "highlight", name: "Highlights" },
  { key: "other", name: "Others" },
  { key: "promo", name: "Promos" },
  { key: "recitation", name: "Recitations" },
];
