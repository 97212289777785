import React from "react";
import { Grid } from "@mui/material";
import HighlightedPlaylist from "./HighlightedPlaylist";
import FeaturedPlaylists from "./FeaturedPlaylists";
import FeaturedTags from "./FeaturedTags";

export default function HomePage() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <HighlightedPlaylist />
      </Grid>
      <Grid item xs={12}>
        <FeaturedPlaylists />
      </Grid>
      <Grid item xs={12}>
        <FeaturedTags />
      </Grid>
    </Grid>
  );
}
