interface UploadProps {
  url: string;
  file: File;
  method: string;
  headers: { [key: string]: string };
  onProgress?: (progress: number) => void;
  onComplete?: () => void;
  onError?: () => void;
}

interface UploadToGCSProps {
  signedURL: string;
  file: File;
  onProgress?: (progress: number) => void;
  onComplete?: () => void;
  onError?: () => void;
  acl?: string;
  cache?: boolean;
}

export function upload({
  url,
  file,
  method,
  headers,
  onProgress,
  onComplete,
  onError,
}: UploadProps) {
  var xhr = new XMLHttpRequest();
  var uploaded = 0;
  xhr.upload.onprogress = function (evt) {
    if (evt.lengthComputable && onProgress) {
      onProgress(evt.loaded - uploaded);
      uploaded = evt.loaded;
    }
  };
  xhr.onload = function (evt) {
    if (onComplete) {
      onComplete();
    }
  };
  xhr.onerror = function (evt) {
    if (onError) {
      onError();
    }
  };
  xhr.open(method, url);
  for (const headerName in headers) {
    xhr.setRequestHeader(headerName, headers[headerName]);
  }
  xhr.send(file);
}

export function uploadToGCS({
  signedURL,
  file,
  onProgress,
  onComplete,
  onError,
}: UploadToGCSProps) {
  const headers: UploadProps["headers"] = {};
  headers["Content-Type"] = file.type;
  // Uploading the file
  upload({
    url: signedURL,
    file: file,
    headers: headers,
    onProgress: onProgress,
    onComplete: onComplete,
    onError: onError,
    method: "PUT",
  });
}

export function uploadToS3({
  signedURL,
  file,
  onProgress,
  onComplete,
  onError,
}: UploadToGCSProps) {
  const headers: UploadProps["headers"] = {};
  headers["Content-Type"] = file.type;
  // Uploading the file
  upload({
    url: signedURL,
    file: file,
    headers: headers,
    onProgress: onProgress,
    onComplete: onComplete,
    onError: onError,
    method: "PUT",
  });
}
