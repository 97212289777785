import React from "react";

import Card from "components/Card";
import ConfirmDialog from "components/ConfirmDialog";
import AnnouncementDialog from "./Dialog";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import FileDialog from "components/FileDialog";

import { apiCall } from "functions/api";
import { useAppContext, useURLBase } from "functions/context";

import type { Announcement } from "types/announcements";
import type { StorageProviderType } from "types/utils";

const AnnouncementsPage = () => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [fileDialogOpen, setFileDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<Announcement[]>();
  const [selectedAnnouncement, setSelectedAnnouncement] =
    React.useState<Announcement>();
  const storageURLBase = useURLBase();
  const context = useAppContext();

  const columns = React.useMemo<GridColDef<Announcement>[]>(
    () => [
      { field: "id", headerName: "ID", width: 90 },
      {
        field: "name",
        headerName: "Name",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.metadata?.name,
        width: 150,
      },
      {
        field: "image",
        headerName: "Image",
        renderCell: (params) => {
          const announcement = params.row;
          if (announcement.image.main) {
            const link =
              storageURLBase[announcement.image.main.source] +
              announcement.image.main.id;
            return (
              <a href={link} target="_blank" rel="noreferrer">
                View Image
              </a>
            );
          }
          return <em>Not Found</em>;
        },
        width: 150,
      },
      {
        field: "description",
        headerName: "Description",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.metadata?.description,
        width: 500,
      },
      {
        field: "actions",
        type: "actions",
        align: "right",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            label="Edit"
            showInMenu
            onClick={() => handleEdit(params.row)}
          />,
          <GridActionsCellItem
            label="Edit Image"
            showInMenu
            onClick={() => handleEditImage(params.row)}
          />,
          <GridActionsCellItem
            label="Delete"
            showInMenu
            onClick={() => handleDelete(params.row)}
          />,
        ],
      },
    ],
    []
  );

  const loadData = () => {
    setLoading(true);
    apiCall("/announcements").then(
      (response: { data: Announcement[] }) => {
        setData(response.data);
        setLoading(false);
      },
      (e) => {
        console.error(e);
      }
    );
  };

  const handleEdit = (row: Announcement) => {
    setSelectedAnnouncement(row);
    setEditDialogOpen(true);
  };

  const handleEditImage = (row: Announcement) => {
    setSelectedAnnouncement(row);
    setFileDialogOpen(true);
  };

  const editImage = async (file?: string, storage?: StorageProviderType) => {
    if (!selectedAnnouncement) {
      return;
    }
    const request = {
      image: {
        main: file
          ? {
              id: file,
              source: storage!,
            }
          : null,
      },
    };
    await apiCall(`/announcements/${selectedAnnouncement.id}`, "POST", request);
  };

  const handleDelete = (row: Announcement) => {
    setSelectedAnnouncement(row);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = (reload: boolean) => {
    setEditDialogOpen(false);
    setDeleteDialogOpen(false);
    setFileDialogOpen(false);
    setSelectedAnnouncement(undefined);
    if (reload) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card
        title="Announcements"
        button="Add"
        buttonOnClick={() => setEditDialogOpen(true)}
        padding={false}
        loading={loading}
        empty={data?.length === 0}
      >
        <DataGrid
          rows={data || []}
          columns={columns}
          pageSizeOptions={[50]}
          rowSelection={false}
          disableRowSelectionOnClick
          disableColumnMenu
        />
      </Card>
      <AnnouncementDialog
        open={editDialogOpen}
        onClose={handleDialogClose}
        announcement={selectedAnnouncement}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        title="Delete Announcement"
        text="Are you sure you want to delete this announcement?"
        apiLink={`/announcements/${selectedAnnouncement?.id}`}
      />
      <FileDialog
        open={fileDialogOpen}
        title="Edit Image"
        type="image"
        onClose={handleDialogClose}
        deleteFile={
          (selectedAnnouncement?.image?.main || undefined) && editImage
        }
        editFile={editImage}
      />
    </div>
  );
};

export default AnnouncementsPage;
