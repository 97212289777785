import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import Card from "components/Card";
import ValidatedInput, { initialValue } from "components/ValidatedInput";

import { apiCall, setAuthCookie } from "functions/api";

const LoginLayout = () => {
  const [email, setEmail] = React.useState(initialValue("email"));
  const [password, setPassword] = React.useState(initialValue("password"));
  const [error, setError] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    setError("");
    apiCall(
      "/login",
      "POST",
      { email: email?.value, password: password?.value },
      ""
    )
      .then((response: { token: string }) => {
        setSubmitting(false);
        if (response.token) {
          setAuthCookie(response.token);
          window.location.reload();
        }
      })
      .catch((e) => {
        setSubmitting(false);
        setError("Invalid email or password");
      });
  };

  return (
    <Box sx={{ paddingTop: "200px" }}>
      <Container maxWidth="xs">
        <form onSubmit={handleSubmit}>
          <Card title="Login">
            <ValidatedInput
              label="Email"
              value={email}
              onChange={setEmail}
              type="email"
            />
            <ValidatedInput
              label="Password"
              value={password}
              onChange={setPassword}
              type="password"
            />
            <br />
            {error && (
              <span style={{ color: "red", fontSize: "0.75em" }}>{error}</span>
            )}
            <br />
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={!email?.success || !password?.success || submitting}
            >
              Login
            </Button>
          </Card>
        </form>
      </Container>
    </Box>
  );
};

export default LoginLayout;
