import React from "react";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Dialog from "components/Dialog";

export type ReorderItem = { id: number; label: string };

interface Props {
  open: boolean;
  data?: ReorderItem[];
  onSubmit: (data: ReorderItem[]) => Promise<void>;
  onClose: () => void;
}

interface SortableItemProps {
  item: ReorderItem;
}

function SortableItem(props: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.item.id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: "10px",
    backgroundColor: "#e5e5e5",
    borderRadius: "3px",
    boxSizing: "border-box",
    marginBottom: "5px",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.item.label}
    </div>
  );
}

const ReorderDialog = (props: Props) => {
  const [items, setItems] = React.useState<ReorderItem[]>();
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleClose = () => {
    if (!submitting) {
      props.onClose();
    }
  };

  const handleSubmit = () => {
    if (!items) return;
    setSubmitting(true);
    setServerError("");
    props
      .onSubmit(items)
      .then(() => {
        setSubmitting(false);
        props.onClose();
      })
      .catch((e) => {
        console.error(e);
        setSubmitting(false);
        setServerError("An error occurred");
      });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items!.findIndex((item) => item.id === active.id);
        const newIndex = items!.findIndex((item) => item.id === over.id);

        return arrayMove(items!, oldIndex, newIndex);
      });
    }
  };

  React.useEffect(() => {
    if (props.open) {
      setItems(props.data || []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  if (!items) {
    return null;
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Reorder Media"
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: submitting ? "Submitting" : "Submit",
          color: "primary",
          submit: true,
          disabled: submitting,
        },
      ]}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((item) => (
            <SortableItem key={item.id} item={item} />
          ))}
        </SortableContext>
      </DndContext>
      {serverError && (
        <span style={{ color: "red", fontSize: "0.75em" }}>{serverError}</span>
      )}
    </Dialog>
  );
};

export default ReorderDialog;
