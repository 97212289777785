import { SxProps, Theme } from "@mui/material";
import {
  drawerWidth,
  boxShadow,
  transition,
  whiteColor,
  hexToRgb,
  grayColor,
  blackColor,
  primaryColor,
  primaryBoxShadow,
} from "./main";

const drawerPaperSx: SxProps<Theme> = (theme) => ({
  border: "none",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  zIndex: "1",
  ...boxShadow,
  width: drawerWidth,
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
    position: "fixed",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    zIndex: "1032",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    transform: `translate3d(${drawerWidth}px, 0, 0)`,
    ...transition,
  },
});
const sidebarWrapperSx: SxProps<Theme> = {
  position: "relative",
  height: "calc(100vh - 70px)",
  overflow: "auto",
  width: "260px",
  zIndex: "4",
  overflowScrolling: "touch",
};
const backgroundSx: SxProps<Theme> = {
  position: "absolute",
  zIndex: "1",
  height: "100%",
  width: "100%",
  display: "block",
  top: "0",
  left: "0",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  "&:after": {
    position: "absolute",
    zIndex: "3",
    width: "100%",
    height: "100%",
    content: '""',
    display: "block",
    background: whiteColor,
    opacity: ".8",
  },
};

const brandSx = {
  position: "relative",
  padding: "15px 15px",
  zIndex: "4",
  "&:after": {
    content: '""',
    position: "absolute",
    bottom: "0",
    height: "1px",
    right: "15px",
    width: "calc(100% - 30px)",
    backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)",
  },
  "& a": {
    padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: blackColor,
    },
  },
};

const listItemSx = {
  width: "auto",
  transition: "all 300ms linear",
  margin: "10px 15px 0",
  borderRadius: "3px",
  position: "relative",
  display: "block",
  padding: "10px 15px",
  backgroundColor: "transparent",
};

const listItemActiveSx = {
  backgroundColor: primaryColor[0],
  ...primaryBoxShadow,
  "&:hover,&:focus": {
    backgroundColor: primaryColor[0],
    ...primaryBoxShadow,
  },
};

const listItemIconSx = {
  width: "24px",
  height: "30px",
  fontSize: "24px",
  lineHeight: "30px",
  float: "left",
  marginRight: "15px",
  textAlign: "center",
  verticalAlign: "middle",
  color: "rgba(" + hexToRgb(blackColor) + ", 0.8)",
  ".active &": {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
  },
};

const listItemTextSx = {
  margin: "0",
  lineHeight: "30px",
  fontSize: "14px",
  color: blackColor,
  ".active &": {
    color: whiteColor,
  },
};

export {
  drawerPaperSx,
  sidebarWrapperSx,
  backgroundSx,
  brandSx,
  listItemSx,
  listItemActiveSx,
  listItemIconSx,
  listItemTextSx,
};
