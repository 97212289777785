/*eslint-disable*/
import React from "react";
import { Box, Link } from "@mui/material";
import { containerSx, grayColor, primaryColor } from "assets/jss/main";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        ...containerSx,
        bottom: "0",
        borderTop: "1px solid " + grayColor[11],
        paddingTop: "15px",
        paddingBottom: "15px",
        display: "block",
        clear: "both",
      }}
    >
      Powered by{" "}
      <Link
        href="https://softvertex.dev"
        target="_blank"
        sx={{
          color: primaryColor[0],
        }}
      >
        Softvertex
      </Link>
    </Box>
  );
}
