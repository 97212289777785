import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "components/Dialog";
import Select from "components/Select";
import ValidatedInput, { initialValue } from "components/ValidatedInput";
import { InsertRequest, Playlist, PlaylistMetadata } from "types/playlists";
import { apiCall, createAndUploadFile } from "functions/api";
import { Tag } from "types/tags";
import { arrayEquals } from "functions/utils";
import Dropzone from "components/Dropzone";
import { useAppContext } from "functions/context";

interface Props {
  open: boolean;
  playlist?: Playlist;
  playlistTags?: number[];
  allTags: Tag[];
  onClose: (reload: boolean) => void;
}

const PlaylistEditDialog = (props: Props) => {
  const context = useAppContext();
  const [name, setName] = React.useState(initialValue("text"));
  const [description, setDescription] = React.useState(initialValue("any"));
  const [location, setLocation] = React.useState(initialValue("text"));
  const [date, setDate] = React.useState(initialValue("date"));
  const [year, setYear] = React.useState(initialValue("number"));
  const [type, setType] = React.useState<"topic" | "event">("topic");
  const [submitting, setSubmitting] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([]);
  const [serverError, setServerError] = React.useState("");
  const [file, setFile] = React.useState<File>();

  const error = React.useMemo(() => {
    return (
      !name.success ||
      !description.success ||
      !location.success ||
      (type === "event" && !date.success) ||
      !year.success
    );
  }, [name, description, location, date, year, type]);

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleDrop = (files: File[]) => {
    if (files.length) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async () => {
    if (error) {
      return;
    }
    setSubmitting(true);
    setServerError("");

    const url = props.playlist
      ? `/playlists/${props.playlist.id}`
      : "/playlists";
    const metadata: PlaylistMetadata = {
      name: name?.value,
      description: description?.value,
      location: location?.value,
      date: type === "event" ? date?.value : undefined,
      year: parseInt(year.value, 10),
    };
    const request: Partial<InsertRequest> = {
      metadata: metadata,
    };
    if (!props.playlist) {
      request.type = type;
    }

    try {
      if (file) {
        const cloudFile = await createAndUploadFile(
          context.defaultStorage,
          file,
          "image"
        );
        request.image = {
          main: { id: cloudFile, source: context.defaultStorage },
        };
      }
      const response = await apiCall(url, "POST", request);
      const id = props.playlist ? props.playlist.id : response.id;
      if (file) {
        try {
          await apiCall(`/playlists/${id}/generate_small_images`, "POST");
        } catch (e) {
          console.error(e);
        }
      }
      const initialTags = [...(props.playlistTags || [])];
      const equal = arrayEquals(
        initialTags.sort(),
        selectedTags.map((tag) => tag.id).sort()
      );
      if (!equal) {
        await apiCall(`/playlists/${id}/tags`, "POST", {
          tags: selectedTags.map((tag) => tag.id),
        });
      }
      setSubmitting(false);
      props.onClose(true);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      setServerError("An error occurred");
    }
  };

  React.useEffect(() => {
    if (props.open) {
      setFile(undefined);
      if (props.playlist) {
        setName(initialValue("text", props.playlist.metadata.name));
        setDescription(
          initialValue("any", props.playlist.metadata.description)
        );
        setLocation(initialValue("text", props.playlist.metadata.location));
        setDate(initialValue("date", props.playlist.metadata.date));
        setYear(
          initialValue("number", props.playlist.metadata.year.toString())
        );
        setType(props.playlist.type);
      } else {
        setName(initialValue("text"));
        setDescription(initialValue("any"));
        setLocation(initialValue("text"));
        setDate(initialValue("date"));
        setYear(initialValue("number"));
        setType("topic");
      }
    }
  }, [props.open, props.playlist]);

  React.useEffect(() => {
    if (props.open) {
      if (props.playlistTags) {
        const tags = props.playlistTags
          .map((tagId) => props.allTags.find((tag) => tag.id === tagId))
          .filter((tag) => !!tag) as Tag[];
        setSelectedTags(tags);
      } else {
        setSelectedTags([]);
      }
    }
  }, [props.open, props.playlistTags, props.allTags]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.playlist ? "Edit Playlist" : "Create Playlist"}
      errorMessage={serverError}
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: submitting ? "Submitting" : "Submit",
          color: "primary",
          submit: true,
          disabled: error || submitting,
        },
      ]}
    >
      <ValidatedInput
        label="Name"
        value={name}
        onChange={setName}
        type="text"
      />
      <Select
        label="Type"
        value={type}
        onChange={setType as any}
        disabled={!!props.playlist}
        items={[
          { value: "event", label: "Event" },
          { value: "topic", label: "Topic" },
        ]}
      />
      <ValidatedInput
        label="Description"
        value={description}
        onChange={setDescription}
        type="any"
      />
      <ValidatedInput
        label="Location"
        value={location}
        onChange={setLocation}
        type="text"
      />
      {type === "event" && (
        <ValidatedInput
          label="Date (YYYY-MM-DD)"
          value={date}
          onChange={setDate}
          type="date"
        />
      )}
      <ValidatedInput
        label="Year"
        value={year}
        onChange={setYear}
        type="number"
      />
      <Autocomplete
        multiple
        options={props.allTags}
        getOptionLabel={(tag: Tag) => tag.metadata.name}
        value={selectedTags}
        onChange={(event, value) => setSelectedTags(value)}
        renderInput={(params) => <TextField {...params} label="Tags" />}
      />
      {!props.playlist && (
        <>
          <label>Image</label>
          <Dropzone type="image" onDrop={handleDrop} file={file} />
        </>
      )}
    </Dialog>
  );
};

export default PlaylistEditDialog;
