import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

type Props = Omit<React.ComponentProps<typeof TextField>, "onChange"> & {
  label: string;
  value: string | undefined;
  items: { value: string; label: string }[];
  onChange: (value: string | undefined) => void;
  multiple?: boolean;
};

const Combo = (props: Props) => {
  const { label, value, items, onChange, ...rest } = props;

  return (
    <Autocomplete
      options={items}
      value={items.find((item) => item.value === value) || null}
      getOptionLabel={(option) => option.label}
      onChange={(e, selected) => onChange(selected?.value || undefined)}
      renderInput={(params) => (
        <TextField {...params} {...rest} label={label} />
      )}
    />
  );
};

export default Combo;
