import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import Card from "components/Card";
import { apiCall } from "functions/api";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Playlist } from "types/playlists";
import SelectPlaylistDialog from "./SelectPlaylistDialog";
import ConfirmDialog from "components/ConfirmDialog";
import ReorderDialog, { ReorderItem } from "components/ReorderDialog";
import { HomeConfig, UpdateHomeConfigRequest } from "types/config";

const LIMIT = 5;

export default function FeaturedPlaylists() {
  const [addOpen, setAddOpen] = React.useState(false);
  const [reorderOpen, setReorderOpen] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<number | null>(null);
  const [data, setData] = React.useState<Playlist[]>();

  const navigate = useNavigate();
  const columns = React.useMemo<GridColDef<Playlist>[]>(
    () => [
      { field: "id", headerName: "ID", width: 90, disableColumnMenu: true },
      {
        field: "name",
        headerName: "Name",
        valueGetter: (params) => params.row.metadata.name,
        width: 300,
      },
      {
        field: "type",
        headerName: "Type",
        valueGetter: (params) =>
          params.row.type === "topic" ? "Topic" : "Event",
        width: 150,
      },
      {
        field: "location",
        headerName: "Location",
        valueGetter: (params) => params.row.metadata.location,
        width: 150,
      },
      {
        field: "date",
        headerName: "Date",
        valueGetter: (params) =>
          params.row.metadata.date || params.row.metadata.year,
        width: 150,
      },
      {
        field: "actions",
        type: "actions",
        align: "right",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            label="View"
            showInMenu
            onClick={() => navigate(`/playlists/${params.row.id}`)}
          />,
          <GridActionsCellItem
            label="Remove"
            showInMenu
            onClick={() => setItemToDelete(params.row.id)}
          />,
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAddDialogSubmit = (playlist: Playlist) => {
    const payload: UpdateHomeConfigRequest = {
      featured_playlists: [
        ...data!.map((playlist) => playlist.id),
        playlist.id,
      ],
    };
    return apiCall("/home", "POST", payload).then(() => {
      setData([...data!, playlist]);
    });
  };

  const handleReorderSubmit = (items: ReorderItem[]) => {
    const payload: UpdateHomeConfigRequest = {
      featured_playlists: items.map((item) => item.id),
    };
    return apiCall("/home", "POST", payload).then(() => {
      setData(items.map((i) => data!.find((p) => p.id === i.id)!));
    });
  };

  const deletePlaylist = () => {
    const payload: UpdateHomeConfigRequest = {
      featured_playlists: data!
        .filter((p) => p.id !== itemToDelete)
        .map((p) => p.id),
    };
    return apiCall("/home", "POST", payload).then(() => {
      setData(data!.filter((p) => p.id !== itemToDelete));
    });
  };

  const handleRemoveDialogClose = () => {
    setItemToDelete(null);
  };

  React.useEffect(() => {
    apiCall("/home").then(
      (response: HomeConfig) => setData(response.featured_playlists),
      (e) => console.error(e)
    );
  }, []);

  return (
    <>
      <Card
        title="Featured Playlists"
        button="Add"
        buttonDisabled={data?.length === LIMIT}
        buttonOnClick={() => setAddOpen(true)}
        loading={!data}
        empty={data?.length === 0}
        padding={false}
        menu={[{ label: "Reorder", onClick: () => setReorderOpen(true) }]}
      >
        <DataGrid
          rows={data || []}
          columns={columns}
          pageSizeOptions={[100]}
          rowSelection={false}
          disableRowSelectionOnClick
        />
      </Card>
      <ConfirmDialog
        text="Are you sure to remove this playlist."
        title="Remove Playlist"
        confirmButton="Remove"
        confirm={deletePlaylist}
        open={itemToDelete !== null}
        onClose={handleRemoveDialogClose}
      />
      <SelectPlaylistDialog
        open={addOpen}
        disabledIds={data?.map((p) => p.id) || []}
        onClose={() => setAddOpen(false)}
        onSubmit={handleAddDialogSubmit}
        playlist={null}
      />
      <ReorderDialog
        data={data?.map((p) => ({ id: p.id, label: p.metadata.name })) || []}
        open={reorderOpen}
        onSubmit={handleReorderSubmit}
        onClose={() => setReorderOpen(false)}
      />
    </>
  );
}
