import React from "react";
import Dialog from "./Dialog";
import Dropzone, { type FileType } from "./Dropzone";
import { createAndUploadFile } from "functions/api";
import { useAppContext } from "functions/context";
import type { StorageProviderType } from "types/utils";

interface Props {
  open: boolean;
  type: FileType;
  title?: string;
  showDelete?: boolean;
  editFile: (cloudFile: string, storage: StorageProviderType) => Promise<any>;
  deleteFile?: () => Promise<any>;
  onClose: (reload: boolean) => void;
}

export default function FileDialog(props: Props) {
  const [file, setFile] = React.useState<File>();
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");
  const [fileProgress, setFileProgress] = React.useState<number>(0);
  const context = useAppContext();

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = async () => {
    if (!submitting && file) {
      setServerError("");
      setSubmitting(true);
      try {
        const cloudFile = await createAndUploadFile(
          context.defaultStorage,
          file,
          props.type,
          setFileProgress
        );
        await props.editFile(cloudFile, context.defaultStorage);
        setSubmitting(false);
        props.onClose(true);
      } catch (e: any) {
        setSubmitting(false);
        setFileProgress(0);
        setServerError(e?.message || "An unexpected error occurred.");
      }
    }
  };

  const handleDelete = async () => {
    if (!submitting && props.deleteFile) {
      setServerError("");
      setFileProgress(0);
      setSubmitting(true);
      try {
        await props.deleteFile();
        setSubmitting(false);
        props.onClose(true);
      } catch (e: any) {
        setSubmitting(false);
        setServerError(e?.message || "An unexpected error occurred.");
      }
    }
  };

  const handleDrop = (files: File[]) => {
    if (files.length) {
      setFile(files[0]);
    }
  };

  React.useEffect(() => {
    if (props.open) {
      setFile(undefined);
      setServerError("");
      setFileProgress(0);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      title={props.title || "Edit File"}
      onClose={handleClose}
      onSubmit={handleSubmit}
      errorMessage={serverError}
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          disabled: submitting,
          onClick: handleClose,
        },
        !!props.deleteFile && {
          text: "Delete",
          color: "error",
          disabled: submitting,
          onClick: handleDelete,
        },
        {
          text: "Submit",
          color: "primary",
          submit: true,
          disabled: !file || submitting,
        },
      ]}
      progress={fileProgress ? { value: fileProgress } : undefined}
    >
      <div style={{ marginTop: fileProgress ? "10px" : "0px" }}>
        <Dropzone type={props.type} onDrop={handleDrop} file={file} />
      </div>
    </Dialog>
  );
}
