import Card from "components/Card";
import React from "react";
import { Playlist } from "types/playlists";
import SelectPlaylistDialog from "./SelectPlaylistDialog";
import { Grid, Typography } from "@mui/material";
import ConfirmDialog from "components/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import { apiCall } from "functions/api";
import { HomeConfig, UpdateHomeConfigRequest } from "types/config";

export default function HighlightedPlaylist() {
  const [playlist, setPlaylist] = React.useState<Playlist | null>(null);
  const [changeDialogOpen, setChangeDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    apiCall("/home").then((response: HomeConfig) => {
      setPlaylist(response.top_playlists[0] || null);
      setLoading(false);
    }, console.error);
  }, []);

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
  };


  const removePlaylist = () => {
    const payload: UpdateHomeConfigRequest = { top_playlists: [] };
    return apiCall(`/home`, "POST", payload).then(() => {
      setPlaylist(null);
    });
  };
  const handleChangeSubmit = (p: Playlist) => {
    const payload: UpdateHomeConfigRequest = { top_playlists: [p.id] };
    return apiCall("/home", "POST", payload).then(() => {
      setPlaylist(p);
    });
  };

  const cardMenu = (playlist || undefined) && [
    {
      label: "Change",
      onClick: () => setChangeDialogOpen(true),
    },
    {
      label: "View Playlist",
      onClick: () => navigate(`/playlists/${playlist?.id}`),
    },
    {
      label: "Remove",
      onClick: () => setRemoveDialogOpen(true),
    },
  ];

  return (
    <>
      <Card
        title="Highlighted Playlist"
        loading={loading}
        button={!playlist ? "Add" : undefined}
        buttonOnClick={() => setChangeDialogOpen(true)}
        menu={cardMenu}
        empty={!playlist && !loading}
      >
        {playlist && (
          <Grid container>
            <Grid item sm={2} xs={6}>
              <Typography variant="caption">Id</Typography>
              <Typography variant="body1">{playlist.id}</Typography>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Typography variant="caption">Name</Typography>
              <Typography variant="body1">{playlist.metadata.name}</Typography>
            </Grid>
            <Grid item sm={2} xs={6}>
              <Typography variant="caption">Type</Typography>
              <Typography variant="body1">{playlist.type}</Typography>
            </Grid>
            <Grid item sm={2} xs={6}>
              <Typography variant="caption">Location</Typography>
              <Typography variant="body1">
                {playlist.metadata.location}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={6}>
              <Typography variant="caption">Date</Typography>
              <Typography variant="body1">
                {playlist.metadata.date || playlist.metadata.year}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Card>
      <ConfirmDialog
        text="Are you sure to remove highlighted playlist."
        title="Remove Highlighted Playlist"
        open={removeDialogOpen}
        onClose={handleRemoveDialogClose}
        confirm={removePlaylist}
        confirmButton="Remove"
      />
      <SelectPlaylistDialog
        open={changeDialogOpen}
        playlist={null}
        onSubmit={handleChangeSubmit}
        onClose={() => setChangeDialogOpen(false)}
      />
    </>
  );
}
