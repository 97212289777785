import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import Card from "components/Card";
import PlaylistEditDialog from "./EditDialog";
import PlaylistDetailsPage from "./PlaylistDetails";

import { apiCall } from "functions/api";
import { Playlist } from "types/playlists";
import { Tag } from "types/tags";

const PlaylistsListPage = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<Playlist[]>();
  const [tags, setTags] = React.useState<Tag[]>();
  const navigate = useNavigate();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      { field: "id", headerName: "ID", width: 90, disableColumnMenu: true },
      {
        field: "name",
        headerName: "Name",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.metadata.name,
        width: 300,
      },
      {
        field: "type",
        headerName: "Type",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.type === "topic" ? "Topic" : "Event",
        width: 150,
      },
      {
        field: "location",
        headerName: "Location",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.metadata.location,
        width: 150,
      },
      {
        field: "date",
        headerName: "Date",
        valueGetter: (params: GridValueGetterParams) =>
          params.row.metadata.date || params.row.metadata.year,
        width: 150,
      },
    ],
    []
  );

  const loadData = () => {
    setData(undefined);
    apiCall("/playlists").then(
      (response) => {
        setData(response.data);
      },
      (e) => console.error(e)
    );
    apiCall("/tags", "GET").then(
      (response) => {
        setTags(response.data);
      },
      (e) => {
        console.error(e);
      }
    );
  };

  const handleDialogClose = (reload: boolean) => {
    setOpen(false);
    if (reload) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card
        title="Playlists"
        button="Add"
        buttonOnClick={() => setOpen(true)}
        loading={!data || !tags}
        empty={data?.length === 0}
        padding={false}
      >
        <DataGrid
          rows={data || []}
          columns={columns}
          pageSizeOptions={[100]}
          rowSelection={false}
          disableRowSelectionOnClick
          onRowClick={(params) => navigate(`/playlists/${params.row.id}`)}
        />
      </Card>
      <PlaylistEditDialog
        open={open}
        onClose={handleDialogClose}
        allTags={tags || []}
      />
    </div>
  );
};

const PlaylistsPage = () => {
  return (
    <Routes>
      <Route path="/" element={<PlaylistsListPage />} />
      <Route path="/:id" element={<PlaylistDetailsPage />} />
      <Route path="/*" element={<Navigate to="/playlists" />} />
    </Routes>
  );
};

export default PlaylistsPage;
