import React from "react";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";

import AdminLayout from "./Admin";
import LoginLayout from "./Login";

import { AppContext, AppContextType, initialContext } from "functions/context";
import { apiCall } from "functions/api";

import { materialThemeOverride } from "assets/jss/main";

const MainLayout = () => {
  const [context, setContext] = React.useState<AppContextType>({
    ...initialContext,
    updateContext: (value) => {
      setContext(value);
    },
  });
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    apiCall("/config", "GET")
      .then((response: any) => {
        setContext({
          ...context,
          awsURLBase: response.aws_url_base,
          gcsURLBase: response.gcs_url_base,
          defaultStorage: response.default_storage_provider,
          isLoggedIn: true,
        });
        setLoading(false);
      })
      .catch((e) => {
        setContext({ ...context, isLoggedIn: false });
        setLoading(false);
      });
  }, []);

  return (
    <AppContext.Provider value={context}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={materialThemeOverride}>
          <BrowserRouter>
            {loading && <LinearProgress />}
            {!loading && context.isLoggedIn && <AdminLayout />}
            {!loading && !context.isLoggedIn && (
              <Routes>
                <Route path="/login" element={<LoginLayout />} />
                <Route path="/*" element={<Navigate to="/login" />} />
              </Routes>
            )}
          </BrowserRouter>
        </ThemeProvider>
      </StylesProvider>
    </AppContext.Provider>
  );
};

export default MainLayout;
