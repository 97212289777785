import React from "react";
import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  children?: React.ReactNode;
  width?: "xs" | "sm" | "md" | "lg" | "xl";
  onSubmit?: () => void;
  errorMessage?: string;
  padding?: boolean;
  loading?: boolean;
  actions?: (
    | {
        text: string;
        submit?: boolean;
        onClick?: () => void;
        color:
          | "primary"
          | "secondary"
          | "error"
          | "info"
          | "success"
          | "warning";
        disabled?: boolean;
      }
    | false
  )[];
  progress?: {
    indeterminate?: boolean;
    value?: number;
  };
}

const Dialog = (props: Props) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <MUIDialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={props.width || "xs"}
      fullWidth={true}
      scroll="body"
    >
      <DialogTitle
        sx={{
          fontFamily: '"Quicksand", sans-serif',
        }}
      >
        {props.title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {props.progress && (
        <LinearProgress
          variant={
            props.progress.indeterminate ? "indeterminate" : "determinate"
          }
          value={(!props.progress.indeterminate && props.progress.value) || 0}
        />
      )}
      {!props.loading && (
        <form onSubmit={handleSubmit} style={{ padding: 0, margin: 0 }}>
          <DialogContent
            sx={{
              padding: props.padding === false ? "0" : undefined,
              paddingTop: 0,
            }}
          >
            {props.children}
            {props.errorMessage && (
              <span style={{ color: "red", fontSize: "0.75em" }}>
                {props.errorMessage}
              </span>
            )}
          </DialogContent>
          <DialogActions>
            {props.actions?.map(
              (button, index) =>
                button && (
                  <Button
                    key={index}
                    onClick={button.onClick}
                    color={button.color}
                    disabled={button.disabled}
                    type={button.submit ? "submit" : "button"}
                  >
                    {button.text}
                  </Button>
                )
            )}
          </DialogActions>
        </form>
      )}
      {props.loading && (
        <div style={{ padding: "15px", textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
    </MUIDialog>
  );
};

export default Dialog;
