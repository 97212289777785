import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { apiCall } from "functions/api";
import { ListResponse } from "types/utils";
import { Tag } from "types/tags";

interface Props {
  label?: string;
  value?: Tag | null;
  onChange: (value: Tag | null) => void;
  disabledIds?: number[];
  disabled?: boolean;
}

function TagSearch(props: Props) {
  const [inputValue, setInputValue] = React.useState("");
  const [data, setData] = React.useState<Tag[]>();
  const [isLoading, setIsLoading] = React.useState(true);

  const getOptionLabel = (option: Tag) => {
    return option.metadata.name;
  };

  const isOptionEqualToValue = (option: Tag, value: Tag) =>
    option.id === value.id;

  React.useEffect(() => {
    const id = setTimeout(() => {
      const request = {
        search: inputValue || undefined,
        limit: 20,
      };
      setIsLoading(true);
      apiCall("/tags", "GET", request).then(
        (response: ListResponse<Tag>) => {
          setData(response.data);
          setIsLoading(false);
        },
        (e: any) => {
          setIsLoading(false);
          console.error(e);
        }
      );
    }, 300);

    return () => clearTimeout(id);
  }, [inputValue]);

  return (
    <Autocomplete<Tag>
      options={data?.filter((d) => !props.disabledIds?.includes(d.id)) || []}
      disabled={props.disabled}
      value={props.value}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      loading={isLoading}
      ListboxProps={{ sx: { maxHeight: 200 } }}
      onChange={(_, value) => props.onChange(value)}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField {...params} fullWidth label={props.label} />
      )}
    />
  );
}

export default TagSearch;
