import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";

import Sidebar from "components/Sidebar";
import Footer from "components/Footer";
import Navbar from "components/Navbar";

import routes from "routes";

import { containerSx, drawerWidth, transition } from "assets/jss/main";
import sidebarImage from "assets/img/sidebar.jpg";

export default function AdminLayout() {
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <Box
      sx={{
        position: "relative",
        top: "0",
        height: "100vh",
      }}
    >
      <Sidebar
        logoText="Islamimarkaz"
        routes={routes}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        image={sidebarImage}
      />
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`,
          },
          overflow: "auto",
          position: "relative",
          float: "right",
          maxHeight: "100%",
          width: "100%",
          overflowScrolling: "touch",
          ...transition,
        })}
      >
        <Navbar routes={routes} handleDrawerToggle={handleDrawerToggle} />
        <Box
          sx={{
            marginTop: "70px",
            padding: "30px 15px",
            minHeight: "calc(100vh - 123px)",
          }}
        >
          <Box sx={containerSx}>
            <Routes>
              {routes.map((route, key) => (
                <Route
                  path={route.path}
                  element={<route.element />}
                  key={key}
                />
              ))}
              <Route
                path="*"
                element={<Navigate to={routes[0].path} replace />}
              />
            </Routes>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
