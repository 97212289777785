import React from 'react';
import { clearAuthCookie } from 'functions/api';

const LogoutPage = () => {
  clearAuthCookie();
  window.location.reload();
  return null;
};

export default LogoutPage;
