import React from "react";

import Dialog from "components/Dialog";
import ValidatedInput, { initialValue } from "components/ValidatedInput";
import Dropzone from "components/Dropzone";

import { apiCall, createAndUploadFile } from "functions/api";

import { Announcement } from "types/announcements";
import { useAppContext } from "functions/context";
import { ImageInfo } from "types/utils";

interface Props {
  open: boolean;
  announcement?: Announcement;
  onClose: (reload: boolean) => void;
}

type Request = Partial<
  Omit<Announcement, "image" | "id"> & {
    image: Partial<ImageInfo>;
  }
>;

const AnnouncementDialog = (props: Props) => {
  const [name, setName] = React.useState(initialValue("text"));
  const [description, setDescription] = React.useState(initialValue("text"));
  const [file, setFile] = React.useState<File>();
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");
  const context = useAppContext();

  const error = React.useMemo(() => {
    return !name?.success || !description?.success;
  }, [name, description]);

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleDrop = (files: File[]) => {
    if (files.length) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async () => {
    if (error) {
      return;
    }
    setSubmitting(true);
    setServerError("");
    const url = props.announcement
      ? `/announcements/${props.announcement.id}`
      : "/announcements";
    const request: Request = {
      metadata: {
        name: name?.value,
        description: description?.value,
      },
    };
    try {
      if (file) {
        const cloudFile = await createAndUploadFile(
          context.defaultStorage,
          file,
          "image"
        );
        request.image = {
          main: { id: cloudFile, source: context.defaultStorage },
        };
      }
      const response = await apiCall(url, "POST", request);
      const id = props.announcement ? props.announcement.id : response.id;
      if (file) {
        try {
          await apiCall(`/announcements/${id}/generate_small_images`, "POST");
        } catch (e) {
          console.error(e);
        }
      }
      setSubmitting(false);
      props.onClose(true);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      setServerError("An error occurred");
    }
  };

  React.useEffect(() => {
    if (props.open) {
      setFile(undefined);
      if (props.announcement) {
        setName(initialValue("text", props.announcement.metadata.name));
        setDescription(
          initialValue("text", props.announcement.metadata.description)
        );
      } else {
        setName(initialValue("text"));
        setDescription(initialValue("text"));
      }
    }
  }, [props.open, props.announcement]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.announcement ? "Edit Announcement" : "Create Announcement"}
      errorMessage={serverError}
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: submitting ? "Submitting" : "Submit",
          color: "primary",
          submit: true,
          disabled: error || submitting,
        },
      ]}
    >
      <ValidatedInput
        label="Name"
        value={name}
        onChange={setName}
        type="text"
      />
      <ValidatedInput
        label="Description"
        value={description}
        onChange={setDescription}
        type="text"
      />
      {!props.announcement && (
        <>
          <label>Image</label>
          <Dropzone type="image" onDrop={handleDrop} file={file} />
        </>
      )}
    </Dialog>
  );
};

export default AnnouncementDialog;
